.FormModal {
  .ant-input-group-addon {
    background-color: @secondary-5;
    border: 1px solid @primary-4;
  }

  .ant-radio-button-wrapper {
    background-color: @secondary-5;
  }

}