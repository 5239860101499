@import (reference) '~antd/dist/antd.less';

.tooltip-content{
    color: @black-3;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.63px
  }
  
.ant-tooltip.ant-tooltip-placement-bottom{
    top: 45px !important //tooltip default placement was not design approved in company section. tooltip is appended to end of dom, if we end up using more tooltips we will need to remove.
}