@import (reference) '~antd/dist/antd.less';

h1,
h2,
h3,
h4 {
  font-family: @heading-font-family;
}

h1.ant-typography {
  font-weight: bold !important;
  letter-spacing: 1.90476px;
}

h2.ant-typography {
  font-weight: 500 !important;
  letter-spacing: 1.26984px;
  line-height: 28px !important;
}

h4.ant-typography {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 19px !important;
  color: @primary-1 !important;
  letter-spacing: 0.634921px;
}
