.ant-alert {
  &.ant-alert-banner {
    text-align: center;
    font-size: 17px;
    font-weight: 600;

    &.ant-alert-no-icon {
      padding: 16px 15px;
    }
  }
}
