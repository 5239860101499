@import (reference) '~antd/dist/antd.less';

.text-primary {
  color: @primary-color !important;
}

.text-secondary {
  color: @secondary-color !important;
}

.text-white {
  color: @white !important;
}

.text-lg {
  font-size: @font-size-lg !important;
}

.background-cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.d-flex {
  display: flex !important;
}

.position-absolute {
  position: absolute !important;
}

.position-relative {
  position: relative !important;
}

.d-inline-block {
  display: inline-block !important;
}

.hg-6 {
  height: 3.375rem;
}

.wg-6 {
  width: 3.375rem;
}

.mb-1 {
  margin-bottom: 0.5625rem !important;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-flex-start {
  justify-content: flex-start !important;
}

.d-flex {
  display: flex !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.bold {
  font-weight: bold;
}
