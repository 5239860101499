.FormModal {
  &.ant-modal {
    padding-bottom:0;
    margin: 40px 0;
  }

  .ant-modal-close {
    top: 20px;
    right: 18px;
  }

  .ant-modal-header {
    padding: 45px 40px 30px 40px;
    .ant-modal-title {
      font-weight: bold;
      text-align: center;
      letter-spacing: 0.95px;
      font-size: 24px;
      line-height: 18px;
    }
  }

  .ant-modal-body {
    padding:0 40px 40px 40px;
  }

  .ant-picker-range {
    width:100%;
  }

  h4.ant-typography {
    padding-left: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: @primary-5;
    font-family: Poppins, serif;
    text-transform: uppercase;
    letter-spacing: 0.476px;
    color: @black-3 !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 16px !important;
  }

  .subTitle {
    text-align: center;
    margin-bottom:34px;

    .subTitleText {
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: @black;
    }
  }

  .help {
    font-size: 12px;
    line-height: 17px;
    font-weight: 500;
    letter-spacing: 0.476px;
    color: @primary-color;
    margin-bottom: 20px;
  }

  .formSection {
    /* @TODO - Find a better solution */
    display: block;
    margin-left: -40px;
    min-width: 960px;
    margin-bottom: 14px;
  }

  .buttonContainer {
    padding-bottom: 20px;
    text-align: center;
  }

  .ant-form-item-label > .ant-form-item-required {
    display:block;
    &:before {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: @primary-color;
      position: absolute;
      top:4px;
      right:0;
    }
  }

  // handle error display of *
  .ant-form-item-has-error {
    .ant-form-item-label > .ant-form-item-required {
      &:before {
        color: @error-color;
      }
    }
  }

  .imageRow {
    text-align: center;
    .ant-form-item-explain {
      margin-bottom: 10px;
    }
  }

  .actions {
    text-align: right;
    .positive {
      margin-left: 30px;
    }
  }
}

.ReorderModal {
  .ant-modal-close {
    top: 24px;
    right: 18px;
  }

  .ant-modal-header {
    padding: 17px 40px 0 40px;
    .ant-modal-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .ant-menu-horizontal {
      line-height: inherit;
      border: none;
      margin-right: 54px;
      > .ant-menu-item {
        padding: 30px 0;
        margin-left: 20px;
      }
    }
  }
  .ant-modal-body {
    padding: 0;
    .ant-table {
      background: @white;
      .ant-table-cell {
        border-bottom: 1px solid @black-5;
        padding: 15px 40px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.56px;
        color: @black;
      }
    }
  }
  .actions {
    padding: 40px;
  }

  .formSection {
    margin-left:inherit;
    min-width: inherit;
    margin-bottom:0;
    .ant-typography {
      margin-bottom:0;
    }
  }
}

.row-dragging {
  background: #fafafa;
  padding: 0 23px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.56px;
  color: @black;
  border: 1px solid #ccc;
  z-index: 2000;
  display: flex;
  justify-content: space-between;
}
.row-dragging td {
  padding: 16px;
  visibility: hidden;
}
.row-dragging .drag-visible {
  visibility: visible;
}
