.ant-btn {
  text-transform: uppercase;
  letter-spacing: 1.3px;

  &.secondary {
    background-color: @secondary-2;
    border-color: @secondary-2;

    &:hover,
    &:active,
    &:focus {
      background-color: @secondary-4;
      border-color: @secondary-4;
    }
  }
}
